/* general styles */

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html{
  scroll-padding-top: 120px; /* height of your navbar */
}

:root {
  --bs-dark:#000000;
  --bs-white:#FFFFFF;
  --bs-orange: #ffc455;
  --bs-lightblue: #212c6f;
  --bs-magenta:#ff517e;
}

body {
  font-family: 'Josefin Sans', sans-serif;
  background: rgb(218,218,218);
  background: linear-gradient(0deg, rgba(218,218,218,1) 0%, rgba(255,255,255,1) 100%);
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
  /*background: rgb(7,0,73);
  background: linear-gradient(0deg, rgba(7,0,73,1) 0%, rgba(255,255,255,1) 100%);*/
}

.full-h{
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.btn-secondary,
.btn-primary{
  width: 200px;
  cursor: pointer;
  display: block;
  margin: 15px auto;
  border-radius: 3rem;
  padding: 15px 0;
  transition: all .5s;
  transform: scale(1);
}

.btn-primary{
  border:1px solid var(--bs-orange);
  background: var(--bs-orange);
}

.btn-primary:hover{
  border:1px solid var(--bs-magenta);
  background: var(--bs-magenta);
  transform: scale(0.9);
}

.btn-secondary{
  border:1px solid var(--bs-magenta);
  background: var(--bs-magenta);
}

.btn-secondary:hover{
  border:1px solid var(--bs-orange);
  background: var(--bs-orange);
  transform: scale(0.9);
}

.section.filter{
  filter: blur(7px);
  -webkit-filter: blur(7px);
}

.scroll-to-top {
  background-color: var(--bs-lightblue)!important;
  border-radius: 50%!important;
  width: 48px!important;
  height: 48px!important;
  transition: all .3s;
  animation: fadeInUp .5s ease-in-out 0.1s forwards;
  opacity: 0;
  bottom: 20px;
}

@keyframes fadeInUp{
  from{
    opacity: 0;
    bottom: 20px;
  }to{
    opacity: 1;
    bottom: 40px;
  }
}
