.timeline {
  min-width: 300px;
  max-width: 100%;
  box-shadow: 0px 0px 15px 5px rgb(0 0 0 / 5%);
  padding: 30px;
  background: white;
  border-radius: 2rem;
}

.timeitem {
  font-size: 1.3rem;
  line-height: 1.75rem;
  border-top: 3px solid;
  border-image: linear-gradient(to right, #743ad5 0%, #d53a9d 100%);
  border-image-slice: 1;
  border-width: 3px;
  margin: 0;
  padding: 40px;
  counter-increment: section;
  position: relative;
  color: black;
}

.timeitem p{
  margin-bottom: 0;
}

.timeitem:nth-child(odd) {
  border-right: 3px solid;
}

.timeitem:nth-child(odd):before{
    left: 100%; 
    margin-left: -20px;
}

.timeitem:nth-child(even) {
  border-left: 3px solid;
}

.timeitem:nth-child(even):before {
    right: 100%; 
    margin-right: -20px;
}

.timeitem:first-child {
  border-top: 0;
  border-top-right-radius:0;
  border-top-left-radius:0;
}

.timeitem:last-child {
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
}

.timeyear{
  background: var(--bs-magenta);
  display: block;
  color: var(--bs-white);
  position: absolute;
  padding: 7px 5px 3px 5px;
  border-radius: 1.5rem;
  line-height: 25px;
  font-size: 16px;
  width: 60px;
  text-align: center;
  animation: updo 20s ease-in-out 0s infinite;
  margin-top: 0;
  transition: all 1s;
}

.timeyear.right{
  right: -30px;
  animation-delay: 5s;
}

.timeyear.left{
  left: -30px;
  animation-delay: 2s;
}

@keyframes updo{
  0%{
    margin-top: 0;
  }10%{
    margin-top: 15px;
  }20%{
    margin-top: 0;
  }100%{
    margin-top: 0;
  }
}