#utils{
    min-height: 100vh;
    margin-bottom: 120px;
    background: transparent;
}

.video-card{
    align-items: center;
    text-align: center;
    min-height: 500px;
    justify-content: center;
    padding: 30px;
    border: none;
    box-shadow: 0px 0px 15px 5px rgb(0 0 0 / 5%);
    border-radius: 2rem;
    background: black;
    color:white;
}

.video-card a{
    text-decoration: none;
}
.video-card a button{
    text-transform: uppercase;
}

@media screen and (max-width:956px){
    .video-card{
        display: flex;
        flex-direction: column;
    }
    .video{
        max-width: 100%;
    }
    .video-card-body{
        padding: 30px 15px;
        text-align: center;
    }
}

@media screen and (min-width:957px){
    .video-card{
        display: flex;
        flex-direction: row;
    }
    .video{
        max-width: 200px;
    }
    .video-card .video-card-body{
        padding: 30px 5px 30px 55px;
        text-align: left;
    }
    .video-card.reverse .video-card-body{
        padding: 30px 40px 30px 20px;
        text-align: left;
    }
}

@media screen and (min-width:1024px){
    .video-card.reverse{
        flex-direction: row-reverse;
    }
    .video{
        max-width: 250px;
    }
}

@media screen and (min-width:1300px){
    .video{
        max-width: 450px;
    }
}

@media screen and (min-width:1600px){
    .video{
        max-width: 600px;
    }
}


