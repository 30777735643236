/*
 * reset by marcexl
 * version 26112022
 * for servicios juridicos
 *
 * @all right reserved by marcexl.com.ar 2023
 *
 */

.bg-light{
  min-height: 100px;
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.4);
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.4);
  max-height: 110px;
}

.vertical-nav{
  padding-top: 15px;
  width: 120px;
  text-align: center;
  background: white;
  position: fixed;
  left: 0;
  min-height: 100vh;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
  z-index: 2;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  overflow-Y: auto;
  overflow-X: hidden;
  align-items: flex-start;
}

.vertical-nav > .container{
  flex-direction: column;
  padding: 0;
}

.navbar-brand{
  padding: 0;
  margin: 0;
  position: relative;
  display: block;
  width: 100%;
  height: 120px;
}

.navbar-brand img{
  border-radius: 50%;
  padding: 10px;
  transition: all .5s;
  position: absolute;
  width: 100%;
  transition: all .5s;
  top: 0;
  left: 0;
}

.navbar-brand .avatar-img{
  transform: rotateY(0deg);
  opacity: 1;
}

.navbar-brand .logo-img{
  transform: rotateY(180deg);
  opacity: 0;
}

.navbar-brand:hover .avatar-img{
  transform: rotateY(180deg);
  opacity: 0;
}

.navbar-brand:hover .logo-img{
  transform: rotateY(0deg);
  opacity: 1;
}

.navbar-toggler.collapsed,
.navbar-toggler{
  border:none!important;
  outline:none!important;
  box-shadow: none!important;
}

.navbar-toggler.collapsed .navbar-toggler-icon{
  background: url('./hamburguer.svg');
  transition: all .3s;
}

.navbar-toggler .navbar-toggler-icon{
  background: url('./close.png');
  transition: all .3s;
}

.navbar-expand .navbar-collapse{
  width: 100%;
}

.navbar-expand .navbar-nav{
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
}

.navbar-expand .navbar-nav .nav-link{
  transition: all .3s;
}

.navbar-expand .navbar-nav .nav-link.active-scroll-spy,
.navbar-expand .navbar-nav .nav-link:hover{
  background: #00c8da26;
}

.navbar-expand .navbar-nav .nav-link svg,
.navbar-expand .navbar-nav .nav-link span{
  display: block;
  text-align: center;
  width: 100%;
  transition: all .3s;
}

.navbar-expand .navbar-nav .nav-link svg{
  width: 42px;
  height: 42px;
  margin: 0 auto;
  top: 15px;
  position: relative;
}

.navbar-expand .navbar-nav .nav-link span{
  opacity: 0;
  position: relative;
  top: -15px;
}

.navbar-expand .navbar-nav .nav-link.active-scroll-spy span,
.navbar-expand .navbar-nav .nav-link:hover span{
  top: 0;
  opacity: 1;
}

.navbar-expand .navbar-nav .nav-link.active-scroll-spy svg,
.navbar-expand .navbar-nav .nav-link:hover svg{
  top: 0;
}

.navbar-expand .navbar-nav .nav-link.active-scroll-spy span,
.navbar-expand .navbar-nav .nav-link.blue-link:hover span{
  color:var(--bs-lightblue);
}

.navbar-expand .navbar-nav .nav-link.active-scroll-spy svg,
.navbar-expand .navbar-nav .nav-link.blue-link:hover svg{
  fill: var(--bs-lightblue);
}

.social-icons{
  bottom: 30px;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.social-icons svg{
  width: 32px;
  height: 32px;
  margin: 10px 0;
  fill: var(--bs-nav-link-color);
  cursor: pointer;
}

.social-icons a:hover svg{
  fill: var(--bs-lightblue);
}

#playbox svg{
  fill: var(--bs-magenta)
}

.playarround {
  position: relative;
}

.playarround span{
  position: absolute;
  width: 150px;
  display: block;
  white-space: nowrap;
  bottom: 0;
  color: var(--bs-magenta);
  left: -58px;
  font-size: .7rem;
  text-transform: uppercase;
  letter-spacing: 0;
  opacity: 0;
  transition: all .5s;
}

.playarround:hover span{
  opacity: 1;
  bottom: -14px;
}

#closebox{
  display: none;
}




@keyframes flagicon{
  0%{
    transform: scale(1);
  }
  5%{
    transform: scale(1.2);
  }
  10%{
    transform: scale(1);
  }
}