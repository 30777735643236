/* services */
#services{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card{
    transition: all .5s;
    transform: scale(1);
    text-align: center;
    min-height: 500px;
    justify-content: center;
    padding: 30px;
    border:none;
    box-shadow: 0px 0px 15px 5px rgb(0 0 0 / 5%);
    border-radius: 2rem;
}

.card h2{
    font-size: 1.6rem;
    letter-spacing: 2px;
}

.card:hover{
    transform: scale(1.1);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(63,81,181,0.6);
    -moz-box-shadow: 0px 0px 10px 0px rgba(63,81,181,0.6);
    box-shadow: 0px 0px 10px 0px rgba(63,81,181,0.6);
}
  
.card svg{
    display: block;
    margin: 15px auto;
}

.card a{
    text-decoration: none;
}

@media screen and (max-width:956px){

    #services .row{
        flex-direction: column;
    }
}

@media screen and (min-width:957px){
    .grid-item {
        text-align: center;
        width: 50%;
    }
}

