#contact{
    margin-bottom: 60px;
    position: relative;
}

.row-container{
    background: black;
    border-radius: 2rem;
}

#contact h5{
    font-family: inherit;
    font-size: 19px;
    line-height: 20px;
    font-weight: 800;
    color: white;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#contact h6{
    font-family: inherit;
    text-transform: uppercase;
    font-size: 19px;
    line-height: 20px;
    color: white;
    font-weight: 800;
    letter-spacing: 2px;
    padding-bottom: 26px;
}

#contact .col-md-6.pl-4{
    padding-left: 45px;
}

#contact address{
    font-style: normal;
    font-size: 15px;
    line-height: 27px;
    color: white;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.contact-container-form .col-md-6{
    display: inline-block;
}

.contact-container-form .col-md-6.pr-2{
    padding-right: 20px;
}

.form-control{
    font-family: inherit;
    font-size: 15px;
    line-height: 17px;
    color: white;
    font-weight: 400;
    letter-spacing: 1px;
    padding-left: 0;
    padding-right: 10px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #000000;
    transition-delay: 0s;
    transition-duration: 0.6s;
    transition-property: all;
    transition-timing-function: ease;
    padding-bottom: 19px;
    margin-bottom: 56px;
    margin-right: 35px;
    background: transparent;
}

.form-control:focus {
    border-color: var(--bs-orange);
    outline: 0;
    box-shadow: none;
    background: transparent;
    color: white;
}

address a{
    color: white;
    transition: all .3s;
    text-decoration: none;
}

address a:hover{
    color: var(--bs-orange);
}

address a svg{
    width: 3rem;
    height: 3rem;
}

address a span{
    display: inline-block;
    padding: 5px;
    position: relative;
    top: 3px;
}

.alert{
    display: none;
}

.g-recaptcha {
    margin: 30px 0 45px 0;
}

.spinner-border{
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
}

