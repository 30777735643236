img.lenguaje{
    position: fixed;
    z-index: 1111;
    top: 55px;
    left: 104px;
    width: 36px;
    border-radius: 50%;
    background: white;
    padding: 5px;
    transition: all .3s;
    transform: scale(1);
    animation-name: flagicon;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    cursor: pointer;
}