.squares-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    opacity: 0.5;
}

.squares-container.front{
    z-index: 1;
    background: rgb(255,255,255,0.5);
    cursor: none;
    opacity: 1;
}

.squares-container ul{
    list-style: none;
    padding: 0;
    width: 80px;
    height: 80px;
    display: flex;
    flex-wrap: wrap;
    cursor: none;
    position: absolute;
    animation: animate 25s linear infinite;
    bottom: -150px;
    z-index: 1;
}

.squares-container ul li{
    width: 20px;
    height: 20px;
    background-color: var(--bs-magenta);
    transition: all .5s ease-out;
    opacity: 1;
    transform: scale(1);
}

/* first line */
.squares-container ul li.explode-0{
    transform: translateX(-20px) translateY(-30px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-1{
    transform: translateX(-5px) translateY(-40px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-2{
    transform: translateX(5px) translateY(-40px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-3{
    transform: translateX(20px) translateY(-30px) scale(0.5);
    opacity: 0;
}

/* second line */
.squares-container ul li.explode-4{
    transform: translateX(-30px) translateY(-20px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-5{
    transform: translateX(0px) translateY(-20px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-6{
    transform: translateX(5px) translateY(-20px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-7{
    transform: translateX(30px) translateY(-20px) scale(0.5);
    opacity: 0;
}

/* third line */
.squares-container ul li.explode-8{
    transform: translateX(-30px) translateY(20px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-9{
    transform: translateX(0px) translateY(20px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-10{
    transform: translateX(5px) translateY(20px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-11{
    transform: translateX(30px) translateY(20px) scale(0.5);
    opacity: 0;
}

/* four line */
.squares-container ul li.explode-12{
    transform: translateX(-20px) translateY(30px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-13{
    transform: translateX(-5px) translateY(40px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-14{
    transform: translateX(5px) translateY(40px) scale(0.5);
    opacity: 0;
}

.squares-container ul li.explode-15{
    transform: translateX(20px) translateY(30px) scale(0.5);
    opacity: 0;
}

/* explode */
.beforeexplode{
    animation: bexplode .15s ease-in-out 0s 1 forwards;
    transform: scale(1);
}

@keyframes bexplode {
    0%{
        transform: scale(1);
    }100%{
        transform: scale(1.2);
    }
}

/* squares sizes */
.squares-container ul:nth-child(1){
    left: 25%;
    animation-delay: 0s;
    zoom: 1.2;
}

.squares-container ul:nth-child(2){
    left: 90%;
    animation-delay: 1s;
    zoom: 1.6;
}

.squares-container ul:nth-child(3){
    left: 40%;
    animation-delay: 2s;
    zoom: 0.8;
}

.squares-container ul:nth-child(4){
    left: 15%;
    animation-delay: 4s;
    zoom: 1.8;
}

.squares-container ul:nth-child(5){
    left: 60%;
    animation-delay: 6s;
    zoom: 1;
}

.squares-container ul:nth-child(6){
    left: 80%;
    animation-delay: 8s;
    zoom: 1.4;
}

.squares-container ul:nth-child(7){
    left: 75%;
    animation-delay: 12s;
    zoom: 1.2;
}

.squares-container ul:nth-child(8){
    left: 9%;
    animation-delay: 12s;
    zoom: 1;
}

.squares-container ul:nth-child(9){
    left: 45%;
    animation-delay: 15s;
    zoom: 0.6;
}

.squares-container ul:nth-child(10){
    left: 66%;
    animation-delay: 18s;
    zoom: 0.8;
}

#follower{
    width: 25px;
    height: 25px;
    display: none;
    background: url(./telescopic.png);
    background-size: 100% 100%;
    transform: scale(1);
    animation: follow 2s ease-in-out 0s infinite;
    position: absolute;
}


@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    50%
    {
        transform: translateX(50px);
    }
    100%{
        transform: translateY(-1000px) translateX(0) rotate(720deg);
        opacity: 1;
        border-radius: 50%;
        opacity: 0.75;
    }
}

@keyframes follow {
    0%{
        transform: scale(1);
    }
    50%
    {
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
    
}
