
@media screen and (max-width:956px){
   .container{
      padding-left: 0px;
   }

   .scroll-to-top{
      bottom: 105px!important;
      right: 15px;
   }

   /* marcexl */
   .salute h1 {
      font-size: 3rem;
      margin-left: -5px;
   }

   .marcexl-buttons{
      flex-direction: column;
   }

   img.lenguaje {
      top: initial;
      bottom: 26px;
      left: 55px;
   }

   .full-h {
      min-height: calc(100vh - 100px);
   }

   /* navbar */
   .navbar-brand img{
      margin-top: 7px;
      max-width: 100px;
   }

   .vertical-nav{
      min-height: 60px;
      max-height: 100px;
      bottom: 0;
      width: 100%;
      padding: 0;
   }

   .navbar-brand{
      width: 20%;
      height: 90px;
   }

   .navbar-expand .navbar-collapse{
      width: 75%;
   }

   .vertical-nav > .container,
   .social-icons,
   .navbar-expand .navbar-nav{
      flex-direction: row;
      margin-top: 0;
   }

   .navbar-expand .navbar-nav .nav-link{
      width: 33.333333%;
   }
   .navbar-expand .navbar-nav .nav-link span{
      display: none;
   }
   .navbar-expand .navbar-nav .nav-link.active span{
      font-size: .8rem;
   }
   .navbar-expand .navbar-nav .nav-link.active-scroll-spy,
   .navbar-expand .navbar-nav .nav-link:hover{
      background: transparent;
   }

   .social-icons{
      display: none;
   }

   .navbar-expand .navbar-nav .nav-link svg {
      top: 0;
   }

   /* general */
   .row{
      margin: 0;
      padding: 0;
   }

   .row .col-md-4,
   .row .col-md-8,
   .row .col-md-6{
      padding: 0;
   }

   /* about */
   .about-team h3{
      margin-top: 30px;
   }

   .about-expertise ul{
      padding-left: 0;
   }

   .about-team img{
      width: 100%;
   }

   .about-team{
      border-bottom: none;
   }

   .about-container {
      margin-bottom: -30px;
   }

   .about-expertise.row{
      padding: 0;
   }

   /* contact */
   #contact{
      width: calc(100% - 40px);
      margin-bottom: 100px;
   }

   #contact h5,
   #contact h6 {
      padding-bottom: 10px;
      padding-top: 30px;
      text-align: center;
   }

   #contact .col-md-6.pl-4{
      padding-left: 0px;
      padding-top: 30px;
   }

   #contact-form .col-md-6,
   #contact-form .col-md-6.pr-2{
      padding: 0;
      width: 100%;
   }

   address{
      text-align: center;
      margin-bottom: 30px;
   }

   address a svg{
      display: none!important;
   }

   address a span{
      font-size: 1rem;
   }

   /* timeline */
   .timeline{
      padding: 10px;
   }

   .timeitem p{
      font-size: 1rem;
   }

   .card{
      margin-bottom: 30px;
      min-height: 380px;
   }

   .card h2{
      font-size: 1.2rem;
   }

   /* reset */
   #services,
   #projects,
   #services{
      min-height: auto;
   }

   /* projects */
   #projects .row{
      flex-direction: column;
   }

   #projects .col .project-thumb,
   .br1,
   .br2,
   .br3,
   .br4{
      border-radius: 1rem;
   }

 }

 @media (min-width: 957px){
   .container{
      max-width: 1024px;
      padding-left: 120px;
   }
 }

 @media (min-width: 1400px){
   .container{
      max-width: 1152px;
   }
 }


 @media (min-width: 1600px){
   .container{
      max-width: 1320px;
   }
 }