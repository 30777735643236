#projects{
    min-height: 100vh;
    margin-bottom: 120px;
    background: transparent;
}

.grid-container {
    display: flex;
    flex-wrap: wrap;
}

.grid-item {
    text-align: center;
    width: 50%;
}

.grid-item img{
    width: 100%;
}

.grid-item a{
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.grid-item .project-thumb{
    max-width: 100%;
    width: 100%;
    padding: 5px;
    transform: scale(1);
    transition: all .5s;
}

.grid-item a span{
    position: absolute;
    width: 100%;
    text-align: center;
    color: white;
    opacity: 0;
    transition: all .5s;
    letter-spacing: 5px;
}

.grid-item:hover .project-thumb{
    filter: brightness(0.15);
}

.grid-item:hover a span{
    opacity: 1;
    letter-spacing: normal;
}

.br1{
    border-radius: 3rem 0 0 0;
}

.br2{
    border-radius: 0 3rem 0 0;
}

.br3{
    border-radius: 0 0 0 3rem;
}

.br4{
    border-radius: 0 0 3rem 0;
}

@media screen and (max-width:956px){
    .grid-item {
        text-align: center;
        width: 100%;
    }
}

@media screen and (min-width:957px){
    .grid-item {
        text-align: center;
        width: 50%;
    }
}