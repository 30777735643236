.salute{
    min-height: 215px;
}

.salute h1{
    font-size: 6rem;
    color: var(--bs-lightblue);
    margin-left: -9px;
    text-transform: capitalize;
}

.salute h2{
    position: absolute;
    margin-top: -30px;
    opacity: 0;
    animation: fadeIn 1s ease-in-out 1s forwards;
    border-left: 4px solid #3f51b5;
    padding: 7px 0px 1px 12px;
    line-height: 1rem;
    margin-bottom: 0;
}

.salute h3{
    position: absolute;
    margin-top: -20px;
    opacity: 0;
    animation: fadeInD 1s ease-in-out 0s forwards;
    margin-left: -2px;
    color: white;
    padding: 5px 15px 0px 10px;
}

.salute h3 span{
    color:#000;
}

.salute h3::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 2px;
    margin: 0 auto;
    width: 87px;
    background: var(--bs-orange);
    z-index: -1;
}

.marcexl-buttons{
    position: absolute;
    display: flex;
    padding: 45px 0;
}

.marcexl-buttons a{
    text-decoration: none;
    color: var(--bs-white);
}

.marcexl-buttons button:first-child{
    margin-right: 20px;
}

@keyframes fadeIn{
    from{
        margin-top: -30px;
        opacity: 0;
    }
    to{
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes fadeInD{
    from{
        margin-top: -20px;
        opacity: 0;
    }
    to{
        margin-top: -45px;
        opacity: 1;
    }
}